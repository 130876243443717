import { ReactElement } from 'react';
import * as S from './RecentReviewCard.styles';
import { Rating } from '@/entities/Activity/ui/Rating';
import { RecentReviewsCardProps } from './RecentReviewCard.types';
import { QuotesIcon } from '@/shared/images/icons/Quotes';
import Link from 'next/link';

const RecentReviewsCard = ({ review, innerRef }: RecentReviewsCardProps): ReactElement => {
    const AuthorName = review.user?.split(' ')[0] || '';
    const AuthorFamilyName = review.user?.split(' ')[1]?.split('')[0] || '';

    return (
        <S.RecentReviewCardWrapper className="card" ref={innerRef}>
            <S.ContentContainer>
                <S.Header>
                    <div style={{ display: 'flex' }}>
                        <S.Author>
                            {AuthorName} {AuthorFamilyName}.
                        </S.Author>
                        <S.Time>{review.time}</S.Time>
                    </div>
                    <QuotesIcon />
                </S.Header>

                <S.RatingContainer>
                    <Rating rating={review.rating_stars} />
                    <S.Rate>{Number(review.rate).toFixed(1)}</S.Rate>
                </S.RatingContainer>
                <S.Title>{review.title}</S.Title>
                <S.Description>{review.content}</S.Description>
            </S.ContentContainer>
            <S.BottomWrapper>
                <S.ReviewOnTitle>Reviewed on:</S.ReviewOnTitle>

                <Link href={`${review.trip_slug}/details/${review.trip_id}`.toLowerCase()} passHref>
                    <S.ReviewLink title={review.trip_name}>{review.trip_name || '-'}</S.ReviewLink>
                </Link>
            </S.BottomWrapper>
        </S.RecentReviewCardWrapper>
    );
};

export { RecentReviewsCard };
export default RecentReviewsCard;
