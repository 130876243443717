import { ReactElement } from 'react';
import Link from 'next/link';
import * as S from './LandmarkCard.style';
import URL from '@/common/app/utils/urlsUtils';

type TLandmarkCardProps = {
    item: {
        id: string;
        name: string;
        slug: string;
        link?: string;
    };
    destinationSlug?: string;
    stateCode?: string;
    noMinHeight?: boolean;
    isTopSign?: boolean;
    minWidthFull?: boolean;
};

export const LandmarkCard = ({
    item,
    destinationSlug,
    stateCode,
    noMinHeight,
    isTopSign,
    minWidthFull,
}: TLandmarkCardProps): ReactElement => {
    return (
        <Link
            href={
                item.link?.toLowerCase() ||
                `/destination/${stateCode}/${destinationSlug}/landmarks/${item.slug}/`.toLowerCase()
            }
            passHref
        >
            <S.CardContainer noMinHeight={noMinHeight} minWidthFull={minWidthFull}>
                <S.ImgWrap>
                    <S.Gradient />
                    <S.Image
                        src={`${URL.image}/landmark/${item.id}/700x600/${item.slug}.jpg`}
                        alt={item.slug}
                    />
                </S.ImgWrap>
                <S.Content>
                    <S.Title isTop={isTopSign}>{item.name}</S.Title>
                </S.Content>
            </S.CardContainer>
        </Link>
    );
};
