import Head from 'next/head';
import { buildRootUrl } from '@/common/app/utils/urlsUtils';
import HomePageLight from '@/shared/images/HeroImages/HomePageLight.jpg';

type MetaContentProps = {
    title: string;
    description: string;
    imageSrc?: string;
    slug: string;
    structuredData?: string[];
    ogDescription?: string;
    canonical?: string;
    ogTitle?: string;
    ogUrl?: string;
    ogImageWidth?: string;
    ogImageHeight?: string;
    fbAppId?: string;
    ogType?: string;
    ogImageAlt?: string;
    noIndex?: boolean;
};

export const CommonMeta = ({
    title,
    description,
    imageSrc,
    slug,
    canonical,
    ogTitle,
    fbAppId,
    ogUrl,
    ogType,
    ogImageHeight,
    structuredData = [],
    ogImageAlt,
    noIndex,
}: MetaContentProps) => {
    const imageDefault = HomePageLight.src;

    return (
        <Head>
            <title>{title}</title>
            {description && <meta name="description" key="description" content={description} />}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={ogTitle ?? title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={ogUrl || canonical || buildRootUrl(slug)} />
            <meta property="og:image" content={imageSrc || imageDefault} />
            {/* <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@tripshock" /> */}
            {imageSrc && <meta property="og:image:alt" content={ogImageAlt || ogTitle || title} />}
            {canonical && <link rel="canonical" key="canonical" href={canonical} />}
            {ogImageHeight && <meta property="og:image:height" content={ogImageHeight} />}
            {fbAppId && <meta property="fb:app_id" content={fbAppId} />}
            {ogType && <meta property="og:type" content={ogType} />}
            {structuredData.map((data, index) => (
                <script
                    key={index}
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: data }}
                />
            ))}
            {/* no-index only for LP */}
            {noIndex && <meta name="robots" content="noindex" />}
        </Head>
    );
};
